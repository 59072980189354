.tabulator {
    margin-top: 0 !important;
}

.tabulator-row {
    /* padding: 4px 8px; */
    background-color: #fff !important;
}

.tabulator-frozen-right {
    /* padding: 2px 6px !important; */
    /* padding: 4px 8px !important;  */
    /* background-color: #fff !important;  */
}

.tabulator-frozen {
    /* background-color: #fff !important;  */
}

.tabulator-frozen:hover {
    /* box-shadow: inset 0 0 0 rgb(0 0 0 / 87%);
    background: #e0e0e0!important;
    color: rgba(0,0,0,.87)!important;
    cursor: pointer; */
}

.tabulator .tabulator-row.tabulator-row-even{
    background-color: #EFEFEF !important;
}